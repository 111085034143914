import React from "react"
import styled from "styled-components"

import Img from "gatsby-image"
import Inner from "../../layout/pageInner/Inner"

const InfoRow = ({
  title = "We Stock the following brands",
  subTitle = "Always rest assured that you have the best variety at The Mattress Warehouse.",
  children,
  background = "#fff",
  data = {
    title: "We Stock the following brands",
    subTitle:
      "Always rest assured that you have the best variety at The Mattress Warehouse.",
  },
}) => {
  const { logos } = data

  return (
    <Row background={background}>
      <Inner>
        <TextWrap>
          <Title>{data.title || title}</Title>
          <SubTitle>{data.subTitle || subTitle}</SubTitle>
        </TextWrap>
      </Inner>
      {logos ? <Logos logos={logos} background={background} /> : children}
    </Row>
  )
}

const Logos = ({ logos, background }) => {
  const logoProps = (logo) =>
    logo.image.imageFile.localFile.childImageSharp.fixed
      ? { fixed: logo.image.imageFile.localFile.childImageSharp.fixed }
      : { fluid: logo.image.imageFile.localFile.childImageSharp.fluid }

  return (
    <Inner>
      <Grid>
        {logos &&
          logos.map((logo, idx) => <Img {...logoProps(logo)} key={idx} />)}
      </Grid>
    </Inner>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
  padding: 40px 0;
  align-items: center;
  justify-items: center;
  img {
    mix-blend-mode: multiply;
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    grid-template-columns: 1fr;
  }
`

export default InfoRow

const Row = styled.div`
  background: ${({ background }) => background};
  padding: 60px 0;
`

const TextWrap = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.h2`
  color: #223247;
  border-bottom: 1px solid red;
`
const SubTitle = styled.h3`
  color: #223247;
  font-size: 16px;
  margin-top: 10px;
`
